/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

function getWindowWidth() {
	return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

var getWookmark = function(){

	var wookmark;
	$tiles    = $('#all-news');
	$handler  = $('.mix', $tiles);
	$main     = $('.wrapper-actualite');
	options = {
		resizeDelay: 100,
		autoResize: true, 
		align:'left',
		container: $main,
		offset: 20, 
		itemWidth: '100',
		flexibleWidth: function () {
			if( getWindowWidth() < 768 ) {
				return '100%';
			} else {
				return '33.333333333%';
			}
		}
	};
	// wookmark = $handler.wookmark(options);
	$tiles.imagesLoaded(function() {
		wookmark = new Wookmark('#all-news', options);
		$('#filters .current').trigger('click');
	});

	// // Setup filter buttons when jQuery is available
	var $filters = $('#filters li a');
	$('#filters li a').click(function(e){
		e.preventDefault();
	});

	/**
	* When a filter is clicked, toggle it's active state and refresh.
	*/
	var onClickFilter = function(event) {
        var $item = $(event.currentTarget),
            itemActive = $item.hasClass('active');
            filterType = $item.data('filter');

        if (filterType === 'all') {
          $filters.removeClass('current');
        }else{
	        if (!itemActive) {
	          $filters.removeClass('current');
	          itemActive = true;
	        }
	    }
	    $item.toggleClass('current');
        // Filter by the currently selected filter
        wookmark.filter(itemActive ? [filterType] : []);
      };

	// Capture filter click events.
	$('#filters').on('click.wookmark-filter', 'a', onClickFilter);
};

$(document).ready(function() {
	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	$(".editor-text").fitVids();
	$(".video").on('click', function () {
		if( $(this).hasClass('video--play') ){
			return false;
		}
		$(this).addClass('video--play');
		var video = $(this).find('video');
		video.trigger('play');
		video.attr('controls', '').attr('controlsList','nodownload');
	});

	/**
	 * NAV MAIN
	 */

	$('.nav-main__open').click(function(e) {
		e.preventDefault();
		$('body').addClass('nav-shown');
	});

	$('.nav-main__close').click(function(e) {
		e.preventDefault();
		$('body').removeClass('nav-shown');
	});

	/*
	 ######    ##         ####  ########   ########   ########   
	##    ##   ##          ##   ##     ##  ##         ##     ##  
	##         ##          ##   ##     ##  ##         ##     ##  
	 ######    ##          ##   ##     ##  ######     ########   
	      ##   ##          ##   ##     ##  ##         ##   ##    
	##    ##   ##          ##   ##     ##  ##         ##    ##   
	 ######    ########   ####  ########   ########   ##     ##  
	*/

	$('.owl-carousel').owlCarousel({
		loop: true,
		margin: 0,
		responsiveClass: true,
		items: 1,
		nav: false,
		autoplay: true,
		// autoplayHoverPause: true,
		autoplaySpeed: 600,
		navSpeed: 600
	});


	/*
	##     ##  ########   ##     ##  ########   ########   ########   
	###   ###  ##         ###   ###  ##     ##  ##         ##     ##  
	#### ####  ##         #### ####  ##     ##  ##         ##     ##  
	## ### ##  ######     ## ### ##  ########   ######     ########   
	##     ##  ##         ##     ##  ##     ##  ##         ##   ##    
	##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
	##     ##  ########   ##     ##  ########   ########   ##     ##  
	*/

	$('.member-list__item').click(function(e) {
		$(this).children('.member-list__detail').slideToggle();
	}).children('.member-list__detail').click(function(e) {
		e.stopPropagation();
	});

	/*
	##    ##   ########   ##      ##  ######    
	###   ##   ##         ##  ##  ## ##    ##   
	####  ##   ##         ##  ##  ## ##         
	## ## ##   ######     ##  ##  ##  ######    
	##  ####   ##         ##  ##  ##       ##   
	##   ###   ##         ##  ##  ## ##    ##   
	##    ##   ########    ###  ###   ######    
	*/
	if( $("body").hasClass("actualite") ) getWookmark();

	$('.news-lang-switch a').click(function(e){
		e.preventDefault();
		$('.news-lang-switch a').removeClass('current-lang');
		$(this).addClass('current-lang');
		$('[id^="news-"]').addClass('hidden');
		$( "#"+this.getAttribute('href') ).removeClass('hidden');
	});

	/*
	##    ##   ########   ##      ##  ######    ##         ########   ########   ########   ########   ########   
	###   ##   ##         ##  ##  ## ##    ##   ##         ##            ##         ##      ##         ##     ##  
	####  ##   ##         ##  ##  ## ##         ##         ##            ##         ##      ##         ##     ##  
	## ## ##   ######     ##  ##  ##  ######    ##         ######        ##         ##      ######     ########   
	##  ####   ##         ##  ##  ##       ##   ##         ##            ##         ##      ##         ##   ##    
	##   ###   ##         ##  ##  ## ##    ##   ##         ##            ##         ##      ##         ##    ##   
	##    ##   ########    ###  ###   ######    ########   ########      ##         ##      ########   ##     ##  
	*/

	$('.nav-social__mail').click(function(e) {
		e.preventDefault();

		var page = $(this).attr('href'); // Page cible
		var speed = 750; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
		$('.newsletter-container').addClass('active');
		return false;
	});

	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/

	/**
	 * Gestion de l'élément de formulaire ayant le focus
	 */
	$('.form__input input, .form__input textarea').focus(function(){
		$(this).parent().addClass('current');
	}).blur(function() {
		$(this).parent().removeClass('current');
		if( $(this).val() ) {
			$(this).parent().addClass('filled');
		} else {
			$(this).parent().removeClass('filled');
		}
	});

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("#fcontact").submit(function(){
		if($("#fcontact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/contact/",
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	$("#fnewsletter").submit(function(){
		if($(this).validationEngine('validate')){
			var data = $(this).serialize() + '&act=newsletter';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/contact/",
				success : function(retour) {
					div.empty().append(retour);
					$('.newsletter-container').removeClass('active');
				}
			});
		}
		return false;
	});

	$('#toggleBook').click(function(e) {
		e.preventDefault();
		$('.book-form').addClass('show');
	});


	$("#fbook").submit(function(){
		if($("#fbook").validationEngine('validate')){
			var data = $(this).serialize() + '&act=book';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/contact/",
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});
});
